

export const dataDomande = [
    "Dove eri ieri sera?",
    "Perché non mi hai chiamato?",
    "Con chi stavi parlando al telefono?",
    "A che ora sei tornato a casa?",
    "Cosa hai fatto nel weekend?",
    "Perché non mi hai risposto ai messaggi?",
    "Chi ti ha mandato quel messaggio?",
    "Dove hai comprato quella camicia?",
    "Quanto hai speso per quella macchina?",
    "Perché non mi hai invitato alla tua festa?",
    "Di cosa stavate parlando?",
    "Perché hai cambiato lavoro?",
    "Cosa stai facendo adesso?",
    "Dove vai in vacanza quest'anno?",
    "Perché hai cancellato quella foto?",
    "Con chi eri al ristorante ieri sera?",
    "Perché non sei venuto alla riunione?",
    "Chi è quella persona nelle tue foto?",
    "Dove hai passato la notte scorsa?",
    "Perché hai cambiato numero di telefono?",
    "Cosa c'è di così importante da fare oggi?",
    "Perché non hai risposto alla mia email?",
    "Con chi stai uscendo ultimamente?",
    "Perché hai smesso di seguirmi sui social?",
    "Dove hai trovato quel libro?",
    "Perché non mi hai detto che eri in città?",
    "Chi è il tuo nuovo amico su Facebook?",
    "Perché hai lasciato il gruppo WhatsApp?",
    "Dove sei andato dopo il lavoro ieri?",
    "Perché non ti fai mai vedere con noi?",
    "Chi è quella persona che ti ha chiamato?",
    "Dove hai preso quella borsa?",
    "Perché hai cambiato casa?",
    "Cosa hai fatto il giorno del tuo compleanno?",
    "Con chi sei andato al cinema?",
    "Perché non mi hai parlato dei tuoi piani?",
    "Chi è il tuo nuovo capo?",
    "Dove ti sei tagliato i capelli?",
    "Perché hai smesso di venire in palestra?",
    "Cosa hai fatto con i soldi?",
    "Perché non hai detto niente a nessuno?",
    "Dove sei andato ieri pomeriggio?",
    "Perché non hai risposto alla chiamata?",
    "Chi è la persona che ti ha accompagnato?",
    "Dove ti sei comprato quel vestito?",
    "Perché hai cancellato il post?",
    "Cosa hai fatto nel fine settimana?",
    "Perché non sei venuto alla festa?",
    "Chi ti ha regalato quel braccialetto?",
    "Dove hai comprato quel profumo?",
    "Perché hai smesso di parlare con lui?",
    "Cosa hai detto a quella persona?",
    "Perché hai cambiato idea?",
    "Dove eri quando ti ho cercato?",
    "Perché non ti fai vedere più spesso?",
    "Chi ti ha mandato quei fiori?",
    "Dove hai trovato quell'anello?",
    "Perché non mi hai raccontato tutto?",
    "Cosa stavi pensando in quel momento?",
    "Perché non sei venuto a cena?",
    "Chi ti ha aiutato con quel progetto?",
    "Dove hai parcheggiato la macchina?",
    "Perché hai smesso di scrivermi?",
    "Cosa hai detto al capo?",
    "Perché non hai partecipato all'evento?",
    "Chi è la persona con cui ti vedi spesso?",
    "Dove hai preso quei biglietti?",
    "Perché hai annullato l'appuntamento?",
    "Cosa hai detto a tua madre?",
    "Perché non hai parlato al meeting?",
    "Chi ti ha ispirato quel tatuaggio?",
    "Dove hai trovato quel documento?",
    "Perché non hai risposto alla chiamata urgente?",
    "Cosa stavi leggendo ieri?",
    "Perché non ti sei presentato alla riunione?",
    "Chi è il tuo nuovo compagno di stanza?",
    "Dove hai passato la pausa pranzo?",
    "Perché hai cambiato squadra?",
    "Cosa hai mangiato ieri sera?",
    "Perché non hai detto niente?",
    "Chi ti ha chiamato tardi la sera?",
    "Dove hai nascosto quel regalo?",
    "Perché hai smesso di suonare?",
    "Cosa hai fatto con il vecchio telefono?",
    "Perché non hai chiesto aiuto?",
    "Chi ti ha invitato alla festa?",
    "Dove hai trovato quelle informazioni?",
    "Perché non mi hai raccontato dell'incidente?",
    "Cosa hai fatto dopo il lavoro?",
    "Perché hai smesso di andare a scuola?",
    "Chi è la tua nuova fidanzata?",
    "Dove sei stato tutto il giorno?",
    "Perché non hai detto che eri malato?",
    "Cosa stai studiando?",
    "Perché hai deciso di trasferirti?",
    "Chi ti ha aiutato a traslocare?",
    "Dove hai passato il weekend?",
    "Perché non hai detto niente ai tuoi amici?",
    "Cosa hai fatto con i vecchi vestiti?",
    "Perché hai cambiato banca?",
    "Come mai hai deciso di vestire il tuo gatto da supereroe?",
    "Quante volte al giorno canti sotto la doccia?",
    "Hai un nome segreto per il tuo peluche preferito?",
    "Perché tieni un ananas sotto il cuscino?",
    "Chi è il tuo miglior amico immaginario?",
    "Perché hai comprato 50 rotoli di carta igienica in offerta?",
    "Qual è il tuo rito mattutino più strano?",
    "Perché balli sempre la macarena quando sei solo?",
    "Hai mai parlato con il tuo riflesso nello specchio?",
    "Perché hai un paio di occhiali da sole per ogni giorno della settimana?",
    "Come mai hai un abbonamento annuale a una rivista di giardinaggio se non hai un giardino?",
    "Qual è il tuo snack preferito alle 3 del mattino?",
    "Perché hai imparato a memoria la sigla di ogni cartone animato degli anni '90?",
    "Quante piante hai ucciso quest'anno?",
    "Perché metti il ketchup su tutto, anche sul gelato?",
    "Hai mai provato a convincere il tuo cane a fare yoga con te?",
    "Qual è il tuo superpotere segreto?",
    "Perché hai una collezione di calzini spaiati?",
    "Come mai sai tutte le battute dei film Disney a memoria?",
    "Perché hai deciso di imparare il klingon?",
    "Perché hai una collezione di denti di latte in un barattolo?",
    "Quante volte al giorno parli con il tuo cactus?",
    "Perché balli il tango con il manichino del negozio?",
    "Hai mai cercato di insegnare alla tua tartaruga a fare skateboard?",
    "Perché metti le patatine fritte nel frullatore?",
    "Chi è il tuo fantasma preferito?",
    "Perché porti sempre con te una forchetta di plastica?",
    "Quante ore passi a osservare i piccioni al parco?",
    "Perché ti metti le calze sulle mani quando dormi?",
    "Hai mai cercato di fare amicizia con una nuvola?",
    "Perché hai un album di foto solo di formiche?",
    "Qual è il tuo record personale di cerchi fatti con il fumo?",
    "Perché hai un poster di broccoli in camera tua?",
    "Quante volte hai abbracciato un albero quest'anno?",
    "Perché dai nomi a tutte le tue scarpe?",
    "Hai mai provato a mungere un gatto?",
    "Perché conservi i biglietti del tram del 1995?",
    "Qual è il tuo incantesimo preferito di Harry Potter?",
    "Perché metti il burro di arachidi nei capelli?",
    "Quante canzoni inventi sotto la doccia?",
    "Perché hai un cappello per ogni dito del piede?",
    "Hai mai discusso con la tua ombra?",
    "Perché ti travesti da zombie ogni mercoledì?",
    "Qual è il tuo segreto per attirare i pipistrelli?",
    "Perché hai una collezione di bottiglie vuote di ketchup?",
    "Hai mai cercato di fare il moonwalk sulla sabbia?",
    "Perché dipingi le unghie dei piedi con il pennarello?",
    "Quante ore al giorno passi a cercare il tuo naso finto?",
    "Perché hai un nome per ogni zanzara che incontri?",
    "Hai mai provato a spaventare i pesci con le tue barzellette?",
    "Perché fai il saluto romano al tuo pesce rosso?",
    "Quante volte hai scritto poesie sulle melanzane?",
    "Perché porti una pianta di basilico al cinema?",
    "Hai mai fatto una dichiarazione d'amore a una statua?",
    "Perché hai una collezione di sassi dipinti come emoji?",
    "Qual è il tuo record di ore passate a fissare il soffitto?",
    "Perché balli la salsa con il tuo aspirapolvere?",
    "Hai mai scritto una lettera a Babbo Natale in agosto?",
    "Perché tieni una banana come microfono durante le telefonate?",
    "Quante collezioni di etichette di bottiglie d'acqua hai?",
    "Perché fai i complimenti al frigorifero ogni volta che lo apri?",
    "Hai mai cercato di insegnare alla tua pianta a cantare?",
    "Perché ti fai un selfie con ogni sedia che incontri?",
    "Quante volte hai messo il ketchup nel caffè per errore?",
    "Perché porti sempre con te una maschera da sub?",
    "Hai mai fatto il bucato vestito da clown?",
    "Perché hai una collezione di guanti da cucina usati?",
    "Quante poesie hai scritto ai tuoi calzini?",
    "Perché fai yoga vestito da supereroe?",
    "Hai mai tentato di addestrare una mosca?",
    "Perché parli al frigorifero quando prendi il latte?",
    "Quante volte hai decorato un albero di Natale in estate?",
    "Perché hai una coperta con il volto di un unicorno?",
    "Hai mai portato un orsacchiotto a un appuntamento romantico?",
    "Perché conservi i tuoi vecchi spazzolini da denti?",
    "Quante canzoni hai scritto sulla tua lavatrice?",
    "Perché dipingi i tuoi tappi di sughero?",
    "Hai mai cercato di parlare con i tuoi mobili?",
    "Perché hai un diario segreto dei tuoi sogni sui cetrioli?",
    "Quante ore al giorno parli con il tuo cane immaginario?",
    "Perché tieni le tue scarpe in frigorifero?",
    "Hai mai cercato di far fare yoga alla tua pianta di pomodoro?",
    "Perché fai il giro del quartiere cantando a squarciagola?",
    "Quante volte hai fatto colazione con il cappello da cowboy?",
    "Perché ti vesti da pirata ogni domenica?",
    "Hai mai cercato di fare amicizia con uno scoiattolo?",
    "Perché indossi occhiali da sole di notte?",
    "Quante volte hai parlato con la tua tazza preferita?",
    "Perché conservi i biglietti della lotteria non vincenti?",
    "Hai mai cercato di insegnare alla tua pianta a ballare?",
    "Perché tieni una collezione di piume di piccione?",
    "Quante volte hai cercato di contare le stelle di giorno?",
    "Perché hai un nome per ogni forchetta che possiedi?",
    "Hai mai tentato di addestrare una formica?",
    "Perché ti travesti da vampiro ogni venerdì 13?",
    "Quante poesie hai scritto sulla tua bicicletta?",
    "Perché parli con la tua auto quando la parcheggi?",
    "Hai mai portato un pupazzo di neve al cinema?",
    "Perché tieni una collezione di etichette di banane?",
    "Quante volte hai decorato il tuo gatto per Natale?",
    "Perché indossi una cravatta per andare a letto?",
    "Hai mai cercato di insegnare al tuo pesce a fare i trucchi?",
    "Perché tieni una collezione di sassi dipinti come facce?",
    "Quante ore al giorno passi a pensare alle nuvole?",
    "Perché metti la senape sui biscotti?",
    "Hai mai scritto una canzone per il tuo forno?",
    "Perché fai il solletico ai tuoi cuscini?",
    "Quante volte hai raccontato storie al tuo ventilatore?",
    "Perché porti il tuo peluche preferito al lavoro?",
    "Hai mai cercato di insegnare al tuo gatto a parlare?",
    "Perché tieni una collezione di lattine vuote?",
    "Quante poesie hai scritto sulle tue pantofole?",
    "Perché parli al tuo specchio ogni mattina?",
    "Hai mai portato una pianta a fare una passeggiata?",
    "Perché hai una collezione di mollette per il bucato colorate?",
    "Quante volte hai cercato di addestrare una coccinella?",
    "Perché fai il karaoke con il tuo spazzolino da denti?",
    "Perché non ti fai un po' di cazzi tuoi?",
    "Perché avete deciso di fare questo sito?",
    "Mi ami?",
    "Mi vuoi bene?",
    "Che cosa ci fa una bella ragazza come te in un posto come questo?",
    "Perché così serio?",
    "Tu parli a me?",
    "Qual è il piano?",
    "Come hai fatto a uscire di prigione?",
    "Chi è tuo padre?",
    "Che cosa vuoi dalla vita?",
    "Mi ami?",
    "Di che colore è il tuo cavallo bianco?",
    "Perché non mi guardi negli occhi?",
    "Da dove vieni?",
    "Hai mai danzato con il diavolo nel pallido plenilunio?",
    "Perché sei così misterioso?",
    "Hai qualcosa da nascondere?",
    "Cosa hai in quella valigia?",
    "Qual è il significato della vita?",
    "Perché non mi hai detto la verità?",
    "Dove sono i diamanti?",
    "Vuoi giocare?",
    "Qual è il tuo nome?",
    "Perché hai lasciato la tua città natale?",
    "Sei reale?",
    "Cosa c'è dietro quella porta?",
    "Hai paura?",
    "Cosa c'è in quella scatola?",
    "Perché hai scelto quella vita?",
    "Dove stiamo andando?",
    "Hai mai fatto qualcosa di cui ti sei pentito?",
    "Vuoi scoprire un segreto?",
    "Qual è la tua missione?",
    "Chi sei tu?",
    "Perché hai tradito?",
    "Dove hai trovato questi soldi?",
    "Cosa ti rende felice?",
    "Qual è il tuo sogno?",
    "Perché non ci hai detto nulla?",
    "Come hai ottenuto quel lavoro?",
    "Cosa ti preoccupa?",
    "Dove sei stato tutto questo tempo?",
    "Cosa nascondi?",
    "Perché hai fatto questo viaggio?",
    "Che cosa ti ha spinto a tornare?",
    "Hai mai rubato qualcosa?",
    "Dove sei diretto?",
    "Perché hai lasciato tutto?",
    "Vuoi sapere la verità?",
    "Come ci siamo arrivati qui?",
    "Dove andiamo?",
    "Sei pronto?",
    "Perché mi stai seguendo?",
    "Luke, io sono tuo padre.",
    "Che cos'è Matrix?",
    "Vuoi scendere giù nel cuore dell'oceano con me?",
    "Vuoi dire che Frodo ha l'Anello?",
    "Vuoi una cioccolata?",
    "Hai mai fatto una proposta che non potevano rifiutare?",
    "Ma perché hai una cicatrice a forma di fulmine?",
    "Vuoi sapere come ho avuto queste cicatrici?",
    "Che cosa c'è in quella valigetta?",
    "Vuoi sapere qual è la prima regola del Fight Club?",
    "Di chi si lu fije?",
    "Sai mantenere un segreto?",
    "Sei sveglio?",
    "Hai mai desiderato di essere da qualche altra parte?",
    "Che cosa desideri di più?",
    "Francamente, mia cara, cosa te ne importa?",
    "Tu pensi che l'amore sia una cosa che si vede dall'aspetto?",
    "Hai mai visitato un altro mondo?",
    "Chi chiamerai?",
    "Hai mai visto un dinosauro?",
    "Hai mai visto le navi da combattimento in fiamme al largo dei bastioni di Orione?",
    "Che cosa stai facendo, Dave?",
    "Perché sorridi?",
    "Ricordi chi sei?",
    "Hai mai visto qualcosa di così spaventoso?",
    "Vuoi sapere un segreto?",
    "Cosa ne pensi della mia teoria?",
    "Come stai?",
    "Come vi siete conosciuti?",
    "Quando ti laurei?",
    "Quando ti sposi?",
    "Quando farete un figlio?",
    "Quanto guadagni?",
    "Perché pagare quando prelevo col Bancomat?",
    "Signora Robinson, lei sta tentando di sedurmi. O no?",
    "Non sei troppo basso per essere un Stormtrooper?",
    "Mi trovi buffo?",
    "Sai come lo chiamano un \"Quarto di libbra con il formaggio\" a Parigi?",
    "Perché sei così serio?",
    "È sicuro?",
    "E a te che cosa importa da dove vengo… amico?",
    "Sai chi ti saluta un casino?",
    "Perché ti sei iscritto a ingegneria?",
    "Chi ha mangiato il mio gelato al cioccolato?",
    "Perché hai comprato un cappello dorato?",
    "Dove hai trovato quel quadro incantato?",
    "Chi ha dipinto il tuo viso stanco e vinto?",
    "Dove hai preso quel libro mai letto?",
    "Perché hai ballato sotto il cielo stellato?",
    "Chi ha suonato al tuo portone dorato?",
    "Hai davvero cantato in quel coro fatato?",
    "Che cosa hai trovato nel giardino incantato?",
    "Dove vai con quel sorriso abbagliante e gai?",
]