import {Alert, Box, Button, Container, FormControl, Input, Stack, Typography} from "@mui/joy";
import {useEffect, useState} from "react";
import {Footer} from "./Footer";
import {findBestMatch} from "string-similarity";
import {APIurl} from "../utils/Utils";


export function Ask({questions}) {

    const [domanda, setDomanda] = useState("")
    const [loading, setLoading] = useState(false)
    const [similar, setSimilar] = useState(null)

    useEffect(() => {
        const similarQuestions = findBestMatch(domanda, questions.map(a => a.toUpperCase()))
        if(similarQuestions.bestMatch.rating > 0) {
            setSimilar(similarQuestions.bestMatch)
        } else {
            setSimilar(null)
        }
    }, [domanda])

    return (
        <Box sx={{height:"100vh"}}>
            <Container sx={{height:"100%", display:"flex", flexDirection:"column", justifyContent:"space-between", alignItems:"center", textAlign:"center", py:2}}>
                <Box />
                <Box sx={{width:{xs:"100%", md:"70%", lg:"50%"}}}>
                    <Stack>
                        <Typography level="h1" textTransform="uppercase" fontWeight="bold" sx={{wordBreak: "break-all"}}>
                            SarannoPureCazziMiei
                        </Typography>
                        <Typography level="h4" fontWeight="bold" sx={{mb:3}}>
                            Invia la tua domanda inutile
                        </Typography>
                    </Stack>
                    <Stack direction="column" spacing={2} sx={{width:"100%"}}>
                        <FormControl sx={{width:"100%"}}>
                            <Input type="text"
                                   placeholder="SCRIVI LA TUA DOMANDA INUTILE"
                                   value={domanda}
                                   onChange={e => setDomanda(e.target.value.toUpperCase())}
                                   sx={{textTransform:"uppercase", fontFamily:"Helvetica"}}
                            />
                        </FormControl>
                        {
                            similar !== null ?
                                <Box textAlign="center">
                                    <Typography level="body-sm" sx={{mb:1, opacity:0.75}}>Domande simili già presenti</Typography>
                                    <Alert variant="soft" color="neutral">{similar.target}</Alert>
                                </Box>
                                :
                                null
                        }
                        <Button variant="solid"
                                color="primary"
                                disabled={domanda.trim().length < 6 || loading}
                                sx={{textTransform:"uppercase", fontFamily:"Helvetica"}}
                                onClick={() => {
                                    sendDomanda()
                                        .then(() => {
                                            setLoading(false)
                                            setDomanda("")
                                        })
                                        .catch(() => {
                                            setLoading(false)
                                            window.alert("Si è verificato un errore, riprova")
                                        })
                                }}
                        >
                            Invia
                        </Button>
                    </Stack>
                </Box>
                <Footer />
            </Container>
        </Box>
    )

    async function sendDomanda() {
        setLoading(true)
        try {
            const response = await fetch(`${APIurl}/addPublicQuestion`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({title: domanda})
            })
            if (!response.ok) {
                const error = await response.clone().json();
                alert("Si è verificato un errore");
                console.error(error)
            } else {
                alert("Domanda inviata");
                setDomanda("")
            }
        } catch (e) {
            alert("Si è verificato un errore");
            console.error(e)
        } finally {
            setLoading(false)
        }
    }
}
