import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Home} from "../pages/Home";
import {Page404} from "../pages/Page404";
import {Ask} from "../pages/Ask";
import {Qr} from "../pages/Qr";
import {useEffect, useState} from "react";
import {dataDomande} from "./Data";
import {APIurl} from "./Utils";

export function Router() {

    const [questions, setQuestions] = useState(dataDomande)

    useEffect(() => {
        fetchQuestions().then(() => {})
    }, []);

    async function fetchQuestions() {
        try {
            const response = await fetch(`${APIurl}/getPublicQuestions`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                }
            })
            if (response.ok) {
                const data = await response.json()
                setQuestions(data)
            }

        } catch (error) {
            console.error(error)
        }
    }

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route exact path="/" element={<Home questions={questions} />} />
                    <Route exact path="/ask" element={<Ask questions={questions} />} />
                    <Route exact path="/qr" element={<Qr />} />
                    {/*<Route exact path="/shop" element={<Shop/>}/>*/}
                    <Route exact path="*" element={<Page404 />} />
                </Routes>
            </BrowserRouter>
        </>
    )
}