import './App.css';
import {DarkMode, LightMode} from "@mui/icons-material";
import {Box, CssVarsProvider, useColorScheme} from "@mui/joy";
import {useEffect, useState} from "react";
import {Router} from "./utils/Router";

function App() {
    return (
        <div>
            <CssVarsProvider>
                <Box sx={{
                  minHeight: '100vh',
                  maxWidth: '100vw',
                  backgroundColor: "var(--joy-palette-background-body)",
                }}
                >
                    <ModeToggle/>
                    <Router />
                </Box>
            </CssVarsProvider>
        </div>
    );
}

function ModeToggle() {
  const {mode, setMode} = useColorScheme()
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])
  if(!mounted) return null

  return (
      <Box
          sx={{
            position: 'fixed',
            top: 0,
            right: 0,
            p: 2,
          }}
      >
        <div
            onClick={() => {
              setMode(mode==='light' ? 'dark' : 'light')
            }}
        >
          {
            mode==='light' ?
                <DarkMode
                    sx={{cursor:'pointer'}}
                />
                :
                <LightMode
                    sx={{
                      cursor:'pointer',
                      color:'white',
                    }}
                />
          }
        </div>
      </Box>
  )
}

export default App;
