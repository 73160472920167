import {Box, Container, Typography} from "@mui/joy";
import {useEffect, useRef, useState} from "react";
import {Footer} from "./Footer";
import {IosShare} from "@mui/icons-material";
import {toBlob} from "html-to-image";


export function Home({questions}) {

    const padding = 50;
    const [quote, setQuote] = useState('')
    const [isSpinning, setIsSpinning] = useState(true)
    const [changed, setChanged] = useState(0)
    const imageref = useRef(null)


    useEffect(() => {
        let timer1, timer2;
        setIsSpinning(true);

        // Animazione tipo slot machine
        timer1 = setInterval(() => {
            const randomQuote = questions[Math.floor(Math.random() * questions.length)];
            setQuote(randomQuote);
        }, 100);

        // Fermare l'animazione dopo 3 secondi
        timer2 = setTimeout(() => {
            clearInterval(timer1);
            const finalQuote = questions[Math.floor(Math.random() * questions.length)];
            setQuote(finalQuote);
            setIsSpinning(false);
        }, 3000);

        return () => {
            clearInterval(timer1);
            clearTimeout(timer2);
        };
    }, [changed]);


    return (
        <Box sx={{height:"100vh"}}>
            <div className="canvasShare" ref={imageref}>
                <h2>{quote}</h2>
                <div>
                    <p>SARANNOPURECAZZIMIEI</p>
                    <p><small>PUNTOIT</small></p>
                </div>
            </div>
            <Container sx={{height:"100%", display:"flex", flexDirection:"column", justifyContent:"space-between", alignItems:"center", textAlign:"center", py:2}}>
                <Box />
                <Box sx={{perspective:"1000px"}} onClick={() => {
                    if(!isSpinning) setChanged(changed+1)
                }}>
                    <Typography level="h1" fontWeight="bold" sx={{mb:5}} className={`quote ${isSpinning ? 'spinning' : ''}`}>
                        {quote}
                    </Typography>
                    <Typography level="h4" textTransform="uppercase" fontWeight="bold">
                        SarannoPureCazziMiei
                    </Typography>
                </Box>
                <Footer />
            </Container>
            <Box
                sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    p: 2,
                }}
            >
                {
                    !isSpinning ?
                        <div
                            onClick={() => shareImage()}
                        >
                            <IosShare
                                sx={{cursor:'pointer'}}
                            />
                        </div>
                        :
                        null
                }
            </Box>
        </Box>
)

    async function shareImage() {
        const newFile = await toBlob(imageref.current);
        const files = {
            files: [
                new File([newFile], 'image.png', {
                    type: 'image/png',
                    lastModified: new Date().getTime(),
                }),
            ]
        }

        try {
            await navigator.share(files)
        } catch (err) {
            console.error(err);
        }
    }

}
