import {Box, Container, Typography} from "@mui/joy";
import {Footer} from "./Footer";


export function Page404() {
    return (
        <Box sx={{height:"100vh"}}>
            <Container sx={{height:"100%", display:"flex", flexDirection:"column", justifyContent:"space-between", alignItems:"center", textAlign:"center", py:2}}>
                <Box />
                <Box>
                    <Typography level="h1" fontWeight="bold" fontSize="8rem" sx={{mb:5}}>
                        404
                    </Typography>
                    <Typography level="h1" fontWeight="bold" sx={{mb:3}}>
                        Perché non esiste questa pagina?
                    </Typography>
                    <Typography level="h4" textTransform="uppercase" fontWeight="bold">
                        SarannoPureCazziMiei
                    </Typography>
                </Box>
                <Footer />
            </Container>
        </Box>
)
}